import React, { Component } from 'react'
import Board from 'react-trello'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils'

import moment from 'moment'
import {
  message,
  Card,
  Tooltip,
  Popconfirm,
  notification,
  Modal,
  Switch,
  Avatar,
  Row,
  Col,
  Popover,
  AutoComplete,
  Spin,
  Tag,
  Input,
  Button,
  Icon,
  Select,
  Drawer,
  Form,
  Checkbox,
} from 'antd'
import Request, {
  getLeadStageColor,
  getStatusStyle,
  getLeadTypeColor,
} from '../../../request'
import _ from 'lodash'
import styles from './styles.less'
import { connect } from 'react-redux'
import { getUrlPushWrapper, getUrlParams } from '../../../routes'
import AddLead from '../add/modalIndex'
import UpdateLead from '../add/editLead'
import { Typography } from 'antd'
import { stateFilter } from '../../../states'
import { addLeadActivity } from './helpers' // CommonJs : require('react-on-screen').default

const { Paragraph } = Typography

const { Option } = Select

class AllLeads extends Component {
  constructor(props) {
    super(props)
    this.table = React.createRef()
    const { dispatch } = props
    this.state = {
      leadStatuseArr: ['Open', 'won', 'Unqualified', 'Lost'],
      allContacts: [],
      showBoard: false,
      showTable: true,
      currentPage: 1,
      leadStage: ['New (Untouched)', 'Contacted', 'Today Followup'],
      priceValue: ['Below 10K', 'From 10K to 50K', 'From 50K to 1 Lakh', 'Above 1 Lakh'],
      boardData: { lanes: [] },
      key: Math.random(),
      RoList: [],
      selectedRowKeys: [],
      selectedRO: undefined,
      lockUnlockLead: [],
    }
    this.paramsDone =  false
    this.searchContact = _.debounce(this.handleContactSearch, 1000, true)
    let searchParams = getUrlParams('openStageUrl', window.location.pathname)
    if (searchParams) {
      let { value } = searchParams

      if (value === 'NewUntouched') {
        this.state.selectedLeadStage = ['New (Untouched)']
      } else {
        this.state.selectedLeadStage = [value]
      }
    }
  }

  copyContent = ''

  content = async (cardId) => {
    let x = await Request.getLeadById({ _id: cardId })
    if (!x.error) {
      this.setState({
        leadData: x.data,
      })
      if (!x.data.contact) {
        //  return "No number found";
        this.copyContent = 'No number found'
      } else {
        // return <div>
        //   <Paragraph copyable>{x.data.contact.mobile}</Paragraph>
        // </div>
        this.copyContent = (
          <div>
            <Paragraph copyable>{x.data.contact.mobile}</Paragraph>
          </div>
        )
      }
    }
  }

  apiRequest = (params) => {
    if (params.page) {
      this.state.currentPage = params.page
    }
    let { contactNameId } = this.state

    if (!params.sortOrder) {
      params.sortField = 'createdAt'
      params.sortOrder = 'desc'
    }
    // if (!params.stage || !params.stage.length) {
    //     params.stage = this.state.leadStage
    //
    // }
    if (this.state.selectedLeadStage) {
      params.stage = this.state.selectedLeadStage
    }

    params.status = 'Open'

    return new Promise(async (resolve) => {
      if (this.state.unassignedLeads) {
        params.officer = { $exists: false }
      }
      params.isChild = false
      params.isVault = false

      if (params.done && params.done.length) {
        this.paramsDone = true
      }

      let data = await Request.getAllLeads({
        ...params,
        customQuery: {
          contact: contactNameId ? contactNameId : undefined,
          // officer: this.paramsDone === true ? { $exists: true, $ne: null } : undefined,
          officer: this.state.officerId ? this.state.officerId : undefined,
          // $or: [{leadApprovedByAdmin: {$in: [true]}}, {leadApprovedByAdmin: {$exists: false}}]
          ...(this.paramsDone === true ? {
            $or: [
              { stage: { $ne: 'Contacted' } },
              { $and: [
                { stage: 'Contacted' },
                { tags: { $ne: 'No Future FollowUp'} }
              ]},
            ],
            officer: { $exists: true, $ne: null },
          } : {})
        },
        regExFilters: ['title', 'company', 'contact'],
      })
      _.each(data.data, (item, i) => {
        if (item.isLocked) {
          this.setLockUnlockLead(i, item.isLocked)
        }
      })

      this.paramsDone = false;

      resolve(data)
      this.setState({ leadData: data, count: data.total })
    })
  }

  showModal = () => {
    this.setState({
      visible: true,
      btnLoader: false,
    })
  }

  handleMake = async () => {
    const { selectedRowKeys } = this.state
    let data = await Request.addTodayFollowup(selectedRowKeys)
    if (!data.error) {
      notification.success({ message: 'Today FollowUp added succesfully' })
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    } else {
      notification.error({ message: 'Error occurred' })
    }
  }

  assignLeads = async () => {
    const { selectedRowKeys, selectedRO } = this.state
    if (selectedRowKeys.length === 0 || !selectedRO) {
      return
    }
    this.setState({ assignLoader: true })
    let { data, error, message } = await Request.assignLeads(selectedRowKeys, {
      officer: selectedRO,
    })
    this.setState({ assignLoader: false })

    if (!error) {
      let foundRO = _.find(this.state.RoList, (item) => {
        return item._id === selectedRO
      })
      if (foundRO) {
        _.each(selectedRowKeys, async (item) => {
          await addLeadActivity({
            values: {
              message: `Lead assign to ${foundRO.name}`,
              leadId: item,
              user: this.props.currentUser.name,
              type: 'info',
            },
          })
        })
      }

      notification.success({ message: message })
      this.table.current.reload()
      this.setState({
        selectedRO: undefined,
        assignLeadDrawer: false,
        selectedRowKeys: [],
      })
    } else {
      notification.error({ message: message })
    }
  }

  async componentDidMount() {
    this.props.dispatch({ type: 'TOGGLE_SIDEBAR_COLLAPSED' })
    let { data: allContacts } = await Request.getAllContacts()
    this.setState({ allContacts, sale: 'Sales' })
    let { data: userList } = await Request.getAllROList({
      customQuery: { userType: 'RO' },
    })
    this.setState({ RoList: userList })
  }

  handleContactSearch = async (test) => {
    // this.setState({​​​​​​​​showSpin: true}​​​​​​​​)
    this.setState({ selectedContact: test })
    if (test == '') {
      this.setState(
        {
          allContacts: this.state.allContacts,
          selectedContact: test,
          contactNameId: null,
        },
        () => this.table.current.reload()
      )
      return false
    }
    let { data, error, message } = await Request.contactSearch({
      text: test,
      type: 'list',
    })
    if (!error) {
      this.setState({ allContacts: data }, () => {
        this.setState({ showSpin: false })
      })
    }
  }

  onContactSelect = (val) => {
    let selectedContact = this.state.allContacts.find((obj) => obj._id === val)
    if (selectedContact) {
      this.setState(
        {
          contactNameId: selectedContact._id,
          selectedContact: `${selectedContact.firstName} (${selectedContact.mobile})`,
        },
        () => {
          this.table.current && this.table.current.reload()
        }
      )
    }
  }

  setLockUnlockLead = (key, value, leadId) => {
    let { lockUnlockLead } = this.state
    let newValue = _.clone(lockUnlockLead)
    newValue[key] = value
    this.setState({ lockUnlockLead: newValue }, () => {
      if (leadId) this.updateTheLead({ _id: leadId, isLocked: value })
    })
  }

  updateTheLead = async (values) => {
    let x = await Request.updateLead(values)
    if (!x.error) {
      notification.success({
        message: x.message,
      })
    } else {
      console.log('err is', x.error)
      notification.error({
        message: 'Error Saving',
        description: x.message,
      })
    }
  }
  changeLeadStatus = async () => {
    const { selectedRowKeys, selectedStatus } = this.state
    if (selectedRowKeys.length === 0 || !selectedStatus) {
      return
    }
    this.setState({ statusLoader: true })
    let { data, error, message } = await Request.updateMultipleLeadStatus(
      selectedRowKeys,
      { status: selectedStatus }
    )
    this.setState({ statusLoader: false })

    if (!error) {
      notification.success({ message: message })
      this.table.current.reload()
      this.setState({
        selectedStatus: undefined,
        statusChangeDrawer: false,
        selectedRowKeys: [],
      })
    } else {
      return notification.error({ message: message })
    }
  }

  render() {
    const RofilterArray =
      this.state.RoList &&
      this.state.RoList.map((user) => {
        return {
          text: user.name,
          value: user._id,
        }
      })

    const { submitting, currentUser, dispatch } = this.props
    const {
      leadStatuseArr,
      allContacts,
      showTable,
      selectedRowKeys,
      RoList,
      assignLeadDrawer,
      selectedRO,
      assignLoader,
      currentPage,
      selectedStatus,
      statusLoader,
    } = this.state

    const columns = [
      {
        title: 'S.No',
        dataIndex: 'sno',
        width: 50,
        key: 'sno',
        fixed: 'left',
        render: (value, item, index) => (currentPage - 1) * 10 + index + 1,
      },

      {
        key: 'actions',
        title: 'View',
        fixed: 'left',
        width: 50,
        render: (text, record) => {
          return (
            <React.Fragment>
              <Tooltip title="View">
                <a
                  href={`/lead/detail?id=${record && record._id}`}
                  target={'_blank'}>
                  <Button
                    style={{ marginLeft: '5px' }}
                    size="small"
                    icon={'eye'}
                    shape={'circle'}
                  />
                </a>
              </Tooltip>
            </React.Fragment>
          )
        },
      },
      {
        title: 'Lead Id',
        dataIndex: 'id',
        key: 'id',
        fixed: 'left',
        searchTextName: 'id',
        width: 100,
      },

      {
        title: 'Lead',
        dataIndex: 'title',
        key: 'title',
        searchTextName: 'title',
        render: (text, record) => (
          <span>
            <a
              onClick={() => {
                this.setState({
                  ...this.state,
                  cardId: record._id,
                  updatelead: true,
                })
              }}>
              {record.title}
            </a>
          </span>
        ),
        // width: 150
      },
        
      {
        title: 'Product',
        dataIndex: 'productName',
        key: 'productName',
      },
      
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        filters: stateFilter,
        width: 150
      },

      {
        title: 'Contact',
        dataIndex: 'contact.firstName',
        key: 'contact.firstName',
        render: (text, record) => (
          <span>
            <span>{record.contact ? record.contact.firstName : ''}</span>
            <br />
            <span>{record.contact ? record.contact.mobile : ''}</span>
          </span>
        ),
        // width: 160
      },

      {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        searchTextName: 'company',
        // width: 100
      },
      {
        title: 'Relationship Officer',
        dataIndex: 'officer',
        key: 'officer',
        //  width: 150,
        filters: RofilterArray,
        render: (val, record) => {
          return (
            <div>
              <div>{val && val.name}</div>
              {/* <div>{val && val.email}</div>
              <div>{val && val.mobile}</div> */}
            </div>
          )
        },
        // width: 100
      },
      {
        title: 'Stage',
        //  dataIndex: 'stage',
        key: 'stage',
        render: (text, record) => {
          return (
            <div>
              {record && record.stage ? (
                <div
                  className={styles.stageColor}
                  style={{
                    backgroundColor: getLeadStageColor(record.stage),
                  }}>
                  {record.stage}
                </div>
              ) : (
                ''
              )}
            </div>
          )
        },
        filters: _.map(this.state.leadStage, (ii) => ({ text: ii, value: ii })),
      },

      // {
      //   title: 'Lead Type',
      //   dataIndex: 'leadType',
      //   key: 'leadType',
      //   filters: [
      //     { text: 'IndiaMART Api', value: 'IndiaMART Api' },
      //     { text: 'Inbound', value: 'Inbound' },
      //     { text: 'Aajjo', value: 'aajjo' },
      //     { text: 'Facebook Ads', value: 'Facebook Ads' },
      //   ],
      //   render: (val, record) => {
      //     return (
      //       <div>
      //         {record.leadType ? (
      //           <div style={getLeadTypeColor(record.leadType)}>
      //             {record.leadType}
      //           </div>
      //         ) : (
      //           ''
      //         )}
      //       </div>
      //     )
      //   },
      //   // width: 100
      // },
      {
        title: 'Total Call Logs',
        dataIndex: 'callLogs',
        key: 'callLogs',
        width: 150,
        render: (val, record) => {
          return (
            <div>
              <small>
                Outgoing:{' '}
                {val && 
                  (val.outgoingCalls || 0) + (val.outgoingRejectCalls || 0)} ({val && 
                    (val.todayOutgoingCalls || 0) + (val.todayOutgoingRejectCalls || 0)})
              </small>
              <div>
                <small>Incoming: {(val && val.incomingCalls) || 0} ({(val && val.todayIncomingCalls) || 0})</small>
              </div>
              <div>
                <small>Missed: {(val && val.missedCalls) || 0} ({(val && val.todayMissedCalls) || 0})</small>
              </div>
              <div>
                <small>Attended: {(val && val.totalAttended) || 0} ({(val && val.todayTotalAttended) || 0})</small>
              </div>
            </div>
          )
        },
        // width: 100
      },

      // {
      //   title: 'Done',
      //   key: 'done',
      //   width: 80,
      //   render: (text, record) => {
      //     if (record.done === true) {
      //       return <Tag color='green' style={{ marginBottom: '2px' }}>Yes</Tag>
      //     } else {
      //       return <Tag color='red' style={{ marginBottom: '2px' }}>No</Tag>
      //     }
      //   },
      //   filters: [{ text: 'Yes', value: 'true' }, { text: 'No', value: 'false' }],
      // },

      // {
      //   title: 'Status/Pipline',
      //   key: 'status',
      //   render: (text, record) => {
      //     return (
      //       <div>
      //         {record.status ? (
      //           <div style={getStatusStyle(record.status)}>{record.status}</div>
      //         ) : (
      //           ''
      //         )}
      //       </div>
      //     )
      //   },
      // },
      // {
      //   title: 'Priority',
      //   key: 'priority',
      //   // width: 120,
      //   render: (text, record) => {
      //     return <div>{record.priority ? record.priority : ''}</div>
      //   },
      //   filters: [
      //     { text: 'Low', value: 'Low' },
      //     { text: 'High', value: 'High' },
      //     { text: 'Medium', value: 'Medium' },
      //   ],
      // },

      {
        title: 'Done',
        key: 'done',
        width: 80,
        render: (text, record) => {
          if (
            record &&
            !record.isVault &&
            record.status === 'Open' &&
            record.officer &&
            record.officer.name &&
            (record.stage !== 'Contacted' ||
              (record.stage === 'Contacted' &&
                !(record.tags && record.tags.includes('No Future FollowUp')) &&
                (!record.followUpDate ||
                  (record.followUpDate &&
                    moment(record.followUpDate).isSame(moment(), 'day')))))
          ) {
            if (record.done === true) {
              return (
                <Tag color="green" style={{ marginBottom: '2px' }}>
                  Yes
                </Tag>
              )
            } else {
              return (
                <Tag color="red" style={{ marginBottom: '2px' }}>
                  No
                </Tag>
              )
            }
          } else {
            return <Tag style={{ marginBottom: '2px' }}>N/A</Tag>
          }

        },
        filters: [{ text: 'Yes', value: 'true' }, { text: 'No', value: 'false' }],
      },

      {
        title: 'Tags',
        key: 'tags',
        width: 140,
        render: (text, record) => {
          if (record.tags && record.tags.length) {
            return _.map(record.tags, (ii) => {
              return <Tag style={{ marginBottom: '2px' }}>{ii}</Tag>
            })
          }
        },
        filters: [{ text: 'No Future FollowUp', value: 'No Future FollowUp' }],
      },

      {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        width: 100,
        render: (text, record) => {
          return record.value && (
            <span>
              <span>{record.currency && record.currency.slice(-1)}{' '}{record.value}</span>
            </span>
          )
        },
        filters: _.map(this.state.priceValue, (ii) => ({ text: ii, value: ii })),
      },

      // {
      //   title: 'Followup on',
      //   dataIndex: 'activities',
      //   key: 'activities',
      //   width: 130,
      //   render: (text, record) => {

      //     const reverseActivities = record.activities && record.activities.reverse();

      //     const followUp = reverseActivities.find((element) => element && element.type === 'followUp');
          
      //     if (followUp) {
      //       // return <span>{followUp.followUpDate}</span>
      //       return <span>{moment(followUp.followUpDate).format('lll')}</span>
      //     }
      //   },
      // },

      // {
      //   title: 'Created At',
      //   dataIndex: 'createdAt',
      //   key: 'createdAt',
      //   sorter: true,
      //   searchDateName: 'createdAt',
      //   render: (val, record) => {
      //     return (
      //       <div>
      //         <div>{moment(record.createdAt).format('lll')}</div>
      //       </div>
      //     )
      //   },
      //   // width:100
      // },
      // {
      //   title: 'Updated At',
      //   dataIndex: 'updatedAt',
      //   key: 'updatedAt',
      //   sorter: true,
      //   searchDateName: 'updatedAt',
      //   render: (val, record) => {
      //     return (
      //       <div>
      //         <div>{moment(record.updatedAt).format('lll')}</div>
      //       </div>
      //     )
      //   },
      //   // width:100
      // },

      {
        title: 'Last Note',
        key: 'lastNote',
        width: 140,
        render: (text, record) => {
          if (record.notes && record.notes.length) {
            return record.notes[record.notes.length - 1] && (
              <span>
                <span>{record.notes[record.notes.length - 1].message && record.notes[record.notes.length - 1].message.replace(/<[^>]+>/g, ' ')}</span>
                <br />
                <small>{moment(record.notes[record.notes.length - 1].date).format('lll')}</small>
              </span>
            )
          }
        },
      },

      {
        title: 'Followup On',
        dataIndex: 'latestFollowup',
        key: 'latestFollowup',
        render: (val, record) => {
          return (
            <div>
              <div>
                {record && record.description ? record.description : ''}
              </div>
              <div>
                {record && record.followUpDate
                  ? moment(record.followUpDate).format('ll')
                  : ''}
              </div>
            </div>
          )
        },
        // width:100
      },

      {
        title: 'Lead Received Date',
        dataIndex: 'leadReceivedDate',
        key: 'leadReceivedDate',
        searchDateName: 'leadReceivedDate',
        sorter: true,
        fixed: 'right',
        render: (val, record) => {
          return (
            <div>
              <div>
                {' '}
                {record && record.leadReceivedDate
                  ? moment(record.leadReceivedDate).format('lll')
                  : ''}{' '}
              </div>
            </div>
          )
        },
        width: 150,
      },
      // {
      //   title: 'Lock/Unlock',
      //   dataIndex: 'isLocked',
      //   key: 'isLocked',
      //   fixed: 'right',
      //   filters: [
      //     { text: 'Locked', value: true },
      //     { text: 'UnLocked', value: false },
      //   ],

      //   render: (val, record, key) => {
      //     return (
      //       <Switch
      //         checked={this.state.lockUnlockLead[key]}
      //         onChange={(vall) => {
      //           this.setLockUnlockLead(key, vall, record && record._id)
      //         }}
      //       />
      //     )
      //   },
      //   width: 80,
      // },
    ]

    const rowSelection = {
      selectedRowKeys: selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys })
      },
      getCheckboxProps: (record) => ({
        _id: record._id,
      }),
    }

    const unassignedLeadsFilterChange = (e) => {
      if (e.target.checked) {
        this.setState({ unassignedLeads: true }, () => {
          this.table.current.reload()
        })
      } else {
        this.state.unassignedLeads = false
        this.table.current.reload()
      }
    }

    return (
      <PageHeaderWrapper
        title={
          this.state.count
            ? `All Open Leads : ${this.state.count}`
            : `All Open Leads: 0`
        }>
        <div style={{ margin: '-24px', padding: '0px' }}>
          <Card>
            <Row>
              <Col span={10}>
                <AutoComplete
                  value={this.state.selectedContact}
                  notFoundContent={this.state.showSpin && <Spin />}
                  autoComplete={'none'}
                  onSelect={this.onContactSelect}
                  onSearch={this.searchContact}
                  onChange={(val) => {
                    this.setState({ selectedContact: val })
                  }}
                  placeholder={'Search Contact'}
                  style={{ width: 300 }}>
                  {allContacts &&
                    allContacts.map((val, item) => (
                      <Option value={val._id}>
                        <>
                          <div>
                            <b>{`${val.firstName} (${val.mobile})`}</b>
                          </div>
                        </>
                      </Option>
                    ))}
                </AutoComplete>
              </Col>

              <Col span={4}>
                <Checkbox onChange={unassignedLeadsFilterChange}>
                  Unassigned Leads
                </Checkbox>
              </Col>
              <Col span={4}></Col>
              <Col span={6} style={{ textAlign: 'end' }}>
                <Tooltip title={'List'}>
                  <Button
                    type={'default'}
                    className={styles.defaultBtn}
                    onClick={() => {
                      this.setState({ showTable: true, showBoard: false })
                    }}>
                    List
                  </Button>
                </Tooltip>
                <Tooltip title={'Board'}>
                  <Button
                    type={'primary'}
                    className={styles.btnPrimary}
                    onClick={() => {
                      this.setState({ showBoard: true, showTable: false })
                    }}>
                    Board
                  </Button>
                </Tooltip>
                <Button
                  type={'primary'}
                  onClick={() => {
                    this.showModal()
                  }}>
                  <Icon type={'plus'} />
                  Add Lead
                </Button>
              </Col>
            </Row>
          </Card>

          <Card className={styles.outerLeadCard}>
            {showTable && (
              <>
                <div className={styles.exportDiv}>
                  <div className={styles.assignBtn}>
                    <Popconfirm
                      title="Are You Sure To make Today FollowUp"
                      disabled={selectedRowKeys.length === 0}
                      onConfirm={(e) => {
                        this.handleMake()
                        // console.log("popconfirm e",e);
                        // message.success('Click on Yes');
                      }}
                      onCancel={(e) => {
                        console.log('popconfirm e', e)
                      }}
                      okText="Yes"
                      cancelText="No">
                      <Button
                        style={{ marginRight: '10px' }}
                        disabled={selectedRowKeys.length === 0}
                        onClick={() =>
                          this.setState({ statusChangePicker: true })
                        }>
                        Today FollowUp <Icon type="right" />
                      </Button>
                    </Popconfirm>
                  </div>
                  <div className={styles.assignBtn}>
                    <Button
                      disabled={selectedRowKeys.length === 0}
                      onClick={() => this.setState({ assignLeadDrawer: true })}>
                      Assign To <Icon type="right" />
                    </Button>
                  </div>
                  <div className={styles.assignBtn}>
                    <Button
                      style={{ marginLeft: '10px' }}
                      disabled={selectedRowKeys.length === 0}
                      onClick={() =>
                        this.setState({ statusChangeDrawer: true })
                      }>
                      Change Status <Icon type="right" />
                    </Button>
                  </div>
                </div>
                <div style={{ backgroundColor: 'white' }}>
                  <TableComp
                    ref={this.table}
                    columns={columns}
                    apiRequest={this.apiRequest}
                    extraProps={{
                      scroll: { x: 1950 },
                      rowSelection: { ...rowSelection },
                    }}
                    //scroll={{x: 1500, y: 300}}
                    id="leadList"
                    pagination={{
                      ...this.state.pagination,
                      defaultPageSize: 10,
                      pageSizeOptions: ['10', '25', '50', '100'],
                      showSizeChanger: true,
                      ...this.props.pagination,
                    }}
                  />
                </div>
              </>
            )}
          </Card>
        </div>
        <Modal
          width={1000}
          visible={this.state.visible}
          onCancel={() => this.setState({ visible: false })}
          maskClosable={true}
          centered={true}
          footer={null}
          keyboard={true}
          title={'Add New Lead'}
          // icon={<QuestionCircle />}
        >
          <AddLead
            allContacts={allContacts}
            handleAddLead={() => {
              this.setState({ visible: false })
              this.table.current.reload()
            }}
          />
        </Modal>

        <Drawer
          title=""
          placement="right"
          closable={true}
          onClose={async () => {
            this.setState({
              ...this.state,
              updatelead: false,
              cardId: '',
            })
          }}
          visible={this.state.updatelead}
          width={800}>
          {/* cardId={cardId} */}
          <UpdateLead
            cardId={this.state.cardId}
            allContacts={allContacts}
            leadOwner={currentUser}
            key={this.state.drawerKey ? this.state.drawerKey : Date.now()}
            handleUpdateLead={() => {
              this.setState({ updatelead: false, cardId: '' })
            }}
          />
        </Drawer>

        <Modal
          title="Assign Leads"
          onCancel={async () => {
            this.setState({
              assignLeadDrawer: false,
              selectedRO: undefined,
            })
          }}
          visible={assignLeadDrawer}
          okText="Assign"
          onOk={this.assignLeads}
          okButtonProps={{
            loading: assignLoader,
          }}>
          <div style={{ textAlign: 'center' }}>
            <Form layout="inline">
              <Form.Item label="RO Officer">
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  style={{ width: 200 }}
                  value={selectedRO}
                  onChange={(selectedRO) => {
                    this.setState({ selectedRO })
                  }}
                  placeholder="Select RO Officer">
                  {RoList &&
                    RoList.map((val, id) => {
                      return (
                        <Option
                          key={val._id}
                          value={
                            val._id
                          }>{`${val.name} (${val.email})`}</Option>
                      )
                    })}
                </Select>
              </Form.Item>
            </Form>
          </div>
        </Modal>
        <Modal
          title="Change Leads Status"
          onCancel={async () => {
            this.setState({
              statusChangeDrawer: false,
              selectedStatus: undefined,
            })
          }}
          visible={this.state.statusChangeDrawer}
          okText="Change"
          onOk={this.changeLeadStatus}
          okButtonProps={{
            loading: statusLoader,
          }}>
          <div style={{ textAlign: 'center' }}>
            <Form layout="inline">
              <Form.Item label="Status">
                <Select
                  showSearch
                  // allowClear
                  optionFilterProp="children"
                  style={{ width: 200 }}
                  value={selectedStatus}
                  onChange={(selectedStatus) => {
                    this.setState({ selectedStatus })
                  }}
                  placeholder="Select Status">
                  {leadStatuseArr &&
                    leadStatuseArr.map((val, id) => {
                      return (
                        <Option key={val} value={val}>
                          {val}
                        </Option>
                      )
                    })}
                </Select>
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  categories: global.categories,
  currentUser: global.currentUser,
  search: router.location.search,
})
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllLeads)
